import {AppConfig} from '../src/app/core/config/config.types'
import {InjectionToken} from '@angular/core'

export function readConfig(): AppConfig {
  if (!process.env['CONFIG']) {
    throw new Error('Could not find CONFIG environment variable')
  }
  return JSON.parse(process.env['CONFIG']) as AppConfig
}

export const APP_CONFIG_TOKEN = new InjectionToken<AppConfig>('BgoAppConfig')
