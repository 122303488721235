export interface AppConfig {
  stage: string
  version: string
  apiUrl: string
  seoDomain: string
  protocol: string
  cognito: {
    region: string
    poolId: string
    appId: string
    oauth?: {
      domain: string
    }
  }
  s3: {
    region: string
  }
  robotsTagHeader: string
  datocms: {
    // preview means unpublished content is visible
    // https://www.datocms.com/docs/content-delivery-api/api-endpoints#preview-mode-to-retrieve-draft-content
    preview: boolean
    apiToken: string
    // when specified, the environment is used to fetch content from DatoCMS, otherwise uses the primary environment
    environment?: string
  }
  googleMaps: {
    apiKey: string
  }
  // Redirect url for the "bring a friend" feature, without any query params
  bringAFriendRedirectUrl: string
  features: AppFeatureFlags
}

export interface AppFeatureFlags {
  /** Generic experimental feature flag */
  enableExperimental: boolean
  payment: {
    /** When enabled, pros can create invoices flagged with immediatePayment, which will send a payment link to client via SMS */
    immediatePayment: boolean
  }
}

/**
 * private fields for lambdas, not to be shared clientside.
 *
 * should not be used in the SSR lambda.
 */
export interface LambdaConfig extends AppConfig {
  region: string
  buckets: {
    assets: string
  }
  datocmsPrivate: {
    cmaApiToken: string
  }
}

export const defaultConfig: AppConfig = {
  stage: 'error',
  version: 'error',
  apiUrl: 'default',
  bringAFriendRedirectUrl: 'default',
  cognito: {
    region: 'default',
    poolId: 'default',
    appId: 'default',
  },
  s3: {
    region: 'default',
  },
  seoDomain: 'default',
  protocol: 'default',
  robotsTagHeader: 'noindex, nofollow',
  datocms: {
    preview: false,
    apiToken: 'default',
  },
  googleMaps: {
    apiKey: 'default',
  },
  features: {
    enableExperimental: false,
    payment: {
      immediatePayment: false,
    },
  },
}
